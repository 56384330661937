$(document).ready(function () {

    $('.slider-product').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.single-slider-nav',
        infinity: true
    });

    $('.single-slider-nav').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.slider-product',
        arrows: false,
        centerMode: true,
        centerPadding: '0px',
        focusOnSelect: true,
        infinity: true
    });
    
    $('.gallery-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        prevArrow: '<button class="btn with-bg icon icon-left-arrow prev"></button>',
        nextArrow: '<button class="btn with-bg icon icon-right-arrow next"></button>',
    })

    $('.slider-jobs').slick({
        slidesToShow: 3,
        slidesToScroll: 2,
        adaptiveHeight: true,
        prevArrow: '<button class="btn with-bg icon icon-left-arrow prev"></button>',
        nextArrow: '<button class="btn with-bg icon icon-right-arrow next"></button>',
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1, 
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 2000
                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1, 
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 2000
                }
            }
        ]
    })
    $('.slider-review').slick({
        slidesToShow: 6,
        slidesToScroll: 2,
        adaptiveHeight: true,
        prevArrow: '<button class="btn with-bg icon icon-left-arrow prev"></button>',
        nextArrow: '<button class="btn with-bg icon icon-right-arrow next"></button>',
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1, 
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 2000
                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1, 
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 2000
                }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1, 
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 2000
                }
            }
        ]
    })
    $('.slider-gallery').slick({
        slidesToShow: 4,
        slidesToScroll: 2,
        adaptiveHeight: true,
        dots: true,
        prevArrow: '<button class="btn with-bg icon icon-left-arrow prev"></button>',
        nextArrow: '<button class="btn with-bg icon icon-right-arrow next"></button>',
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1, 
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 2000
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1, 
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 2000
                }
            }
        ]
    })

});
