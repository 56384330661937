

$(document).on('click', '.accord-header', function(){
    var elem = $(this),
        content = elem.next(),
        item = elem.parent(),
        siblings = item.siblings(),
        dropdowns = siblings.find('.accord-content'),
        icon = elem.children('.icon-bottom');
        plus = elem.children('.icon-plus');


    item.toggleClass('active');
    content.stop().slideToggle(300);

    icon.toggleClass('active');
    plus.toggleClass('active');


    siblings.removeClass('active');
    dropdowns.stop().slideUp(300);
});

$(document).on('click', '.header-filter', function(){
    var elem = $(this),
        content = elem.next(),
        item = elem.parent(),
        siblings = item.siblings(),
        dropdowns = siblings.find('.content-filter'),
        icon = elem.children('.icon-bottom');

    item.toggleClass('active');
    content.stop().slideToggle(300);

    icon.toggleClass('active');

    siblings.removeClass('active');
    dropdowns.stop().slideUp(300);
});

$(document).on('click', '.phone', function(){
    $('.block-phone').toggleClass('active') 
});
$(document).on('click', '.mark', function(){
    $('.list-bl').toggleClass('active')
    $('.return').toggleClass('active')
});
$(document).on('click', '.submark', function(){
    $('.list-bl-sub').toggleClass('active')
    $('.sub-return').toggleClass('active')
});

