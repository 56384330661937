function openPopup(popup, video){
    if(typeof(popup) == 'string'){
        popup = $('.popup.' + popup)
    }

    popup.stop().fadeIn(400)
}

function closePopup(popup){
    if(typeof(popup) == 'string'){
        popup = $('.popup.' + popup)
    }

    popup.stop().fadeOut(400)
}

$('.order-btn').click(function(){
    openPopup('order-popup')
})

$(document).on('click', '.close-popup', function(){
    var elem = $(this),
        popup = elem.parents('.popup'),
        btn = popup.find('.btn')

    closePopup(popup)
    btn.removeAttr('disabled')
})

$(document).ready(function() {

    $('.popup-blackboard, .close-popup').click(function() {
        var popup = $(this).parents('.popup'),
            video = popup.find('video');

        if (video.length) {
            video[0].pause();
        }

        if (popup.hasClass('error-popup')) {
            $('.error-code').slideUp(300);
        }
        popup.fadeOut(300);
    });



    $('.show-code').click(function() {
        $('.error-code').slideToggle(300);
    })

    $('.close-error-popup').click(function() {
        $('.error-code').slideUp(300);
        $('.popup').fadeOut(300);
    })

    $('.open-popup').click(function(e) {
        var link = $(this).data('popup'),
            popup = $('.popup.' + link),
            video = popup.find('video');

        $('.popup').fadeOut(300);
        popup.fadeIn(300);

        if (video.length) {
            video[0].play()
        }

    });

});

$(document).on('click', '.close-popup', function(){
    var elem = $(this),
        popup = elem.parents('.popup'),
        btn = popup.find('.btn')

    closePopup(popup)
    btn.removeAttr('disabled')
})