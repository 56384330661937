$(document).on('click', '#reviews_send', function(e){
    e.preventDefault()

    let elem = $(this)
    let popup = elem.parents('.popup')
    let form = elem.parent()
    let fields = [
        {
            name: 'name',
            required: 1,
        },
        {
            name: 'phone',
            required: 1,
        },
        {
            name: 'email',
            required: 1,
        },
        {
            name: 'review',
            required: 1,
        }
    ]
    let errors = 0
    let rating = $('.review-rating .active').index() + 1
    let data = {rating}

    for(let field of fields) {
        let {name, required} = field
        let input = form.find('[name="' + name + '"]')
        let val = input.get_value()

        if(required) {
            errors += input.check()
        }

        data[name] = val
    }

    if(!rating) {
        addNotif('Ошибка', 'Выберите рейтинг', 'error')
        errors++
    }

    if(!errors) {
        elem.disable()
        requestAjax('add_review', data, () => {
            for(let field of fields) {
                let {name} = field
                let input = form.find('[name="' + name + '"]')
        
                input.clear()
            }

            elem.enable()
            addNotif('Спасибо', 'Ваш отзыв был отправлен на модерацию', 'success')

            popup.stop().fadeOut(300)
        })
    }
})

$('.add-review').click(function(){
    let popup = $('.popup.reviews-popup')

    popup.stop().fadeIn(300)
})