function closeDropdowns(){
    $('.dropdown, .select-dropdown').removeClass('show')
}

function appendOptions(elem, list){
    elem[0].options = list
}

function getOptions(elem, key){
    return elem[0].options[key]
}

function getOptionsList(elem, key, selected){
    var options = getOptions(elem, key),
        structure = ''

    if(options){
        $.each(options, function(key, value){

            if(selected && selected == key){
                structure += '<li class="active" data-id="' + key + '">' + value + '</li>'
            }else{
                structure += '<li data-id="' + key + '">' + value + '</li>'
            }
            
        })   
    }

    return structure
}

function resetSelect(elem, enable, selected){
    elem.removeAttr('data-id').val('')
    elem.next('.select-dropdown').find('li').removeClass('active')

    elem.parents('.form-group').removeClass('error success')

    if(enable){
        elem.removeAttr('disabled')
    }else{
        elem.attr('disabled', true)
    }

    if(selected && elem.hasClass('dynamic-list')){
        var dropdown = elem.next('.select-dropdown'),
            options = getOptionsList(elem, selected)

        dropdown.html(options)

        if(!options) {
            elem.attr('disabled', true)
        }
    }

    if(elem.hasClass('parent')){
        var child = elem.data('child'),
            child = $(child)

        resetSelect(child, false)
    }
}

$(document).on('change', '.toggle-blocks input', function(){
    var elem = $(this),
        items = elem.parents('.toggle-blocks').find('input'),
        index = items.index(elem),
        parent = elem.parents('.toggle-parent'),
        items = parent.find('.toggle')

    items.removeClass('dn').hide()
    items.eq(index).show()
})

$('.review-rating .rr-item').click(function(){
	var elem = $(this),
		parent = elem.parent(),
		items = parent.find('.rr-item');

	parent.removeClass('error');
	items.removeClass('active');
	elem.addClass('active');
})

$(document).on('focus', '.select-wrapper input', function(){
    var elem = $(this),
        parent = elem.parent(),
        dropdown = parent.find('.select-dropdown')

    dropdown.toggleClass('show')
    $(document).click(function (e){
        if (!parent.is(e.target) && parent.has(e.target).length === 0) {
            dropdown.removeClass('show')
        }
    })
})

$(document).on('input', '.select-dropdown li.search input', function(){
    var elem = $(this),
        val = elem.val().toLowerCase(),
        parent = elem.parents('li'),
        items = parent.siblings('li:not(.always-show)')

    items.each(function(){
        var elem = $(this),
            text = elem.find('span').text().toLowerCase()

        if(!text){
            text = elem.text()
        }

        if(text.indexOf(val) < 0){
            elem.hide()
        }else{
            elem.show()
        }

    })
})

$(document).on('click', '.select-dropdown li:not(.search)', function(){
    var elem = $(this),
        text = elem.find('span').text(),
        id = elem.attr('data-id'),
        wrapper = elem.parents('.select-wrapper'),
        dropdown = elem.parents('.select-dropdown'),
        input = wrapper.find('input').eq(0)

    if(!text){
        text = elem.text()
    }

    if(elem.hasClass('clear')){
        input.removeAttr('data-id').val('')
        elem.siblings().removeClass('active')
    }else{
        input.val(text).attr('data-id', id)
        elem.addClass('active').siblings().removeClass('active')
    }

    if(input.hasClass('parent')){
        var child = input.data('child'),
            child = $(child)

        resetSelect(child, true, id)
    }

    dropdown.removeClass('show')
    input.change()
})

$(document).on('change', '.form-group.mark input', function(){
    var elem = $(this),
        elem = elem.next(),
        active_index = elem.index(),
        siblings = elem.siblings('label')

    elem.addClass('active')

    siblings.each(function(){
        var elem = $(this),
            index = elem.index()

        if(index < active_index) {
            elem.addClass('active')
        }else{
            elem.removeClass('active')
        }
    })
})

var selected = []

$(document).on('change', '.form-group.file input', function(e){
    var elem = $(this),
        files = elem[0].files

    $.each(files, function(key, value){
        selected.push(value)
    })
})

$(document).on('click', '.toggler label:not([for])', function(){
    var checkbox = $(this).prev()
    checkbox.prop("checked", !checkbox.prop("checked"))
})

$(document).on('click', '.clone-input', function(e){
    e.preventDefault()

    var elem = $(this),
        max = elem.data('max'),
        parent = elem.parents('.form-group'),
        index = parent.attr('data-index')

    if(!index){
        index = 2
    }else{
        index++
    }
    
    parent.clone().insertAfter(parent)
    var clone = parent.next(),
        input = clone.find('input'),
        label = clone.find('label'),
        id = input.attr('id').replace(/[0-9]/gm, index)

    clone.attr('data-index', index)
    input.attr('id', id)
    label.attr('for', id)
    input.val('')

    if(index == max){
        clone.find('.clone-input').remove()
    }

    if(input.hasClass('phone-mask')){
        phone_mask()
    }
    elem.remove()
})

function clearInput(elem){
    elem.replaceWith(elem.val('').clone(true))
    elem.next('label').find('.avatar-image').remove()
}

$(document).on('change', '.avatar_input', function(e){
    var elem = $(this),
        label = elem.next('label'),
        file = elem[0].files[0],
        max_size = 4

    if(file){

        if(file.type == 'image/jpeg' || file.type == 'image/png'){

            if(file.size <= (max_size * 1048576)){
                var reader = new FileReader()

                reader.readAsDataURL(file)
                reader.onload = function(e) {
                    var image = e.target.result
                    label.append('<div class="avatar-image icon-close" style="background-image: url(' + image + ')"></div>')
                }

            }else{
                clearInput(elem)
                addNotif('Ошибка', 'Недопустимый формат файла', 'error')
            }

        }else{
            clearInput(elem)
            addNotif('Ошибка', 'Недопустимый формат файла', 'error')
        }

    }else{

    }

})

$.fn.get_value = function() {
    if(this.hasClass('inline-date')){
        return this.datepicker('getDate')
    }else if(this.hasClass('select-input')){
        const val = this[0].selected

        if(val){
            return val
        }
    } else if (this.attr('type') == 'checkbox') {
        return this.prop('checked') ? 1 : 0
    }else{
        const has_mask = this[0].mask

        if(has_mask){
            let mask_type = this[0].mask.type
            if(mask_type == 'time' || mask_type == 'date'){
                const val = $.trim(this.val())

                if(!val){
                    return ''
                }else if(val.indexOf('_') != -1){
                    return ''
                }

                return val
            }else if(mask_type == 'slug'){
                const val = $.trim(this.val())
                return val
            }else if(mask_type == 'bik'){
                const val = $.trim(this.val()).toUpperCase()
                return val
            }else{
                return $.trim(this[0].mask.unmaskedValue)
            }
        }else{
            return $.trim(this.val())
        }
    }
}

$.fn.check = function() {
    let errors = 0

    if(this && this.length){
        let parent = this.parents('.form-group')
        if(this.hasClass('inline-date')){
            const val = this.datepicker('getDate')
    
            if(!val){
                errors++
            }
        }else{
            const val = $.trim(this.val())
            const is_masked = this[0].mask ? true : false
        
            if(val){
                parent.removeClass('error')
            }else{
                errors++
                parent.addClass('error')
            }
        
            if(is_masked && !errors){
                if(val.indexOf('_') == -1){
                    parent.removeClass('error')
                }else{
                    errors++
                    parent.addClass('error')
                }
            }
            
            this.on('input', () => {
                parent.removeClass('error')
            })
        
        }
    }

    return errors
}

$.fn.disable = function(append_loading = false, timer) {
    this.attr('disabled', true)
    
    if(append_loading && this.hasClass('btn') && timer){
        this.addClass('loading').append('<span class="timer">' + timer + '</span>')
        let interval

        interval = setInterval(() => {
            timer--

            if(timer > 0){
                this.find('.timer').text(timer)
            }else{
                clearInterval(interval)
                this.enable()
            }
        }, 1000)
    }else if(append_loading && this.hasClass('btn')){
        this.addClass('loading').append('<span class="spinner"></span>')
    }
}

$.fn.enable = function() {
    this.removeClass('loading')
    this.find('.spinner, .timer').remove()
    this.attr('disabled', false)
}

$.fn.clear = function() {
    this.each(function(){
        let elem = $(this)
        if(elem.hasClass('inline-date')){
            return elem.datepicker('getDate')
        }else if(elem.hasClass('select-input')){
            elem[0].selected = null
            elem.update_select()
        }else{
            const has_mask = elem[0].mask
    
            if(has_mask){
                elem[0].mask.unmaskedValue = ''
            }else{
                elem.val('')
            }
        }
    })

    return this
}